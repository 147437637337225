@keyframes like {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  display: inline-block;
  animation: like 2s infinite linear;
  font-size: 40px;
  will-change: transform;
  transform-origin: 50% 50%; /* Adjust as needed */
}
